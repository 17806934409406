<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="24" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div class="text item">
            <el-button style="float: left; padding: 3px 0" type="text" @click="onReturnArticle">返回文章稿件列表</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>更新文章标题</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateArticleTitle">更新</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="titleForm" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="titleForm.title" style="width: 70%; padding-right: 2px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :md="16" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>更新文章内容</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateArticleContent">更新</el-button>
          </div>
          <div>
            <rich-text :text="form.content" style="width: 80%; padding-right: 2px" @content="processContent" />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import RichText from '@/components/RichText'

import { getArticlePost, updateArticleContent } from '@/api/article'

export default {
  name: 'EditArticle',
  components: { RichText },
  data() {
    return {
      titleForm: {
        articleId: null,
        title: null
      },
      form: {
        articleId: null,
        content: null
      }
    }
  },
  created() {
    document.title = '编辑文章稿件'

    const articleId = this.$route.params.articleId
    getArticlePost(articleId).then(resp => {
      if (resp.code === 0) {
        const respData = resp.data
        this.titleForm.articleId = respData.articleId
        this.titleForm.title = respData.title

        this.form.articleId = respData.articleId
        this.form.content = respData.content
      } else {
      }
    })
  },
  methods: {
    processContent(value) {
      this.form.content = value
    },
    onReturnArticle() {
      this.$router.push('/post/list/article')
    },
    onUpdateArticleContent() {
      updateArticleContent(this.form).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            message: '已更新',
            type: 'info',
            duration: 3000
          })
        } else {
          this.$notify({
            message: '更新失败',
            type: 'warning',
            duration: 3000
          })
        }
      })
    },
    onUpdateArticleTitle() {
      this.$message.info('暂未实现')
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
