<template>
  <el-input
    v-model="content"
    style="margin-left: 5px"
    type="textarea"
    :rows="5"
    @blur="onInputBlur"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    onInputBlur() {
      this.$emit('content', this.content)
    }
  }
}
</script>

<style>
</style>
